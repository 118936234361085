import React,{useState} from 'react';
import { Box, Text,Flex,Button,Image } from '@chakra-ui/react';
import StyledBox from './StyleBox';
import StyledTable from './StyleTable';
import mockDataSets from './MockData';
import ScheduledTable from './ScheduleTable';
import YokotenTable from './YokotenTable';

const MonthlyReport=() => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const data = mockDataSets[currentIndex];

    console.log(data["ScheduleTableData"][0])

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
      };
    
      const handleNext = () => {
        setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, mockDataSets.length - 1));
      };
      
  return (
    <Box overflowX="auto" id="divToPrint">
    <Flex direction="column">
    <Flex >
        <Button marginLeft="25px" marginTop="2vh" onClick={handlePrev}>前へ</Button>
        <Flex flexDirection="column" width="20%" marginTop="1vh" marginLeft="5%" textAlign="center">
            <Box><Text>Customer</Text></Box>
            <Box><Text fontSize="20px" fontWeight="bold">{data["Customer"]}</Text></Box>
        </Flex>
        <Flex flexDirection="column" marginTop="1vh" marginLeft="5%" textAlign="center">
            <Box><Text>Application</Text></Box>
            <Box><Text fontSize="20px" fontWeight="bold">{data["Application"]}</Text></Box>
        </Flex>
        <Flex flexDirection="column" marginTop="1vh" marginLeft="5%" textAlign="center">
            <Box><Text>Products</Text></Box>
            <Box><Text fontSize="20px" fontWeight="bold">{data["Products"]}</Text></Box>
        </Flex>
        <Box marginLeft="5%" height="40px" marginTop="1vh" border="2px" borderColor="blue" width="10%" display="flex" alignItems="center" justifyContent="center">
            <Text color="blue" fontSize="20px" fontWeight="bold">{data["Type"]}</Text>
        </Box>
        <Button marginLeft="10px" marginTop="2vh"onClick={handleNext}>次へ</Button>
    </Flex>
      <Flex>
        <Box width="60%" height="60%">
            <Flex direction="row" marginTop="2vh" marginLeft="30px" height="10vh">
                <StyledBox marginRight="10px" width="33%" label="Project Name">{data["Project Name"]}</StyledBox>
                <StyledBox marginRight="10px" width="33%" label="End User">{data["End User"]}</StyledBox>
                <StyledBox marginRight="10px" width="34%" label="MP/Date">{data["MP/Date"]}</StyledBox>
            </Flex>
            <Flex direction="row" marginTop="2vh" marginLeft="30px" height="10vh">
                <StyledBox marginRight="10px" width="33%" label="Application">{data["Application2"]}</StyledBox>
                <StyledBox marginRight="10px" width="33%" label="Territory">{data["Territory"]}</StyledBox>
                <StyledBox marginRight="10px" width="34%" label="Unit/set">{data["Unit/set"]}</StyledBox>
            </Flex>
            <Flex direction="row" marginTop="2vh" marginLeft="30px"height="10vh">
                <StyledBox marginRight="5px" width="20%" label="Target Price">{data["Target Price"]}</StyledBox>
                <StyledBox marginRight="5px" width="20%" label="TTL QTY/Y">{data["TTL QTY/Y"]}</StyledBox>
                <StyledBox marginRight="5px" width="20%" label="MNB QTY/Y">{data["MNB QTY/Y"]}</StyledBox>
                <StyledBox marginRight="5px" width="20%" label="TTL AMT/Y">{data["TTL AMT/Y"]}</StyledBox>
                <StyledBox marginRight="5px" width="20%" label="MNB AMT/Y">{data["MNB AMT/Y"]}</StyledBox>
            </Flex>
            <Text marginLeft="30px" marginTop="20px">Ramp Up Schedule</Text>
            <ScheduledTable 
                width="95%" 
                height="100px" 
                marginLeft="30px"
                scheduleTableData={data["ScheduleTableData"]}
            />
        </Box>
        <Box width="20%" marginTop="2vh" marginLeft="20px">
            <Flex direction="column" height="49vh">
            <Image 
                border="1px"
                borderColor="gray.500" // 例として、境界線の色を指定
                width="100%" 
                height="25vh"
                src={process.env.PUBLIC_URL + data["Photo"]} 
                alt="Photo"
                objectFit="contain"
            />
                <YokotenTable 
                   width="100%" 
                   marginTop="20px" 
                   headerNames={["Other Product", "Yokoten"]}
                   data={data.YokotenTableData}
                   /> 
            </Flex>
        </Box>
      </Flex>
      <Flex direction="column">
         <StyledBox marginRight="20px" marginLeft="30px" width="80%" height="15vh" label="Current Status">{data["Current Status"]}</StyledBox>
         <StyledTable  marginLeft="30px"rows={1} columns={3} width="80%" height="10vh" marginTop="2vh"
         headerNames={["YOKOTEN", "Organizaton Name", "Sales Person Name"]}
         data={data.BasicTableData}/> 
      </Flex>
      </Flex>
    </Box>
  );
};
export default MonthlyReport;
