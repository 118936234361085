import { Table, Thead, Tbody, Tr, Th, Td, Box } from '@chakra-ui/react';

const StyledTable = ({ width, marginTop, headerNames, data=[] }) => {
  // 行の高さとヘッダーの高さをpx単位で設定
  const rowHeight = 30; // 各行の高さ
  const headerHeight = 30; // ヘッダーの高さ
  const numRows = 3; // 行数の固定値またはdata.lengthで動的に設定

  // テーブル全体の高さを計算
  const tableHeight = `${headerHeight + numRows * rowHeight}px`;

  const renderHeaders = () => {
    return headerNames.map((name) => (
      <Th key={name} textAlign="center" border="1px solid black">{name}</Th>
    ));
  };

  const renderRows = () => {
    let rows = [];

    for (let i = 0; i < numRows; i++) {
      rows.push(
        <Tr key={i}>
          {headerNames.map((header, index) => (
            <Td key={`${i}-${index}`} border="1px solid black" textAlign="center">{data[i] ? data[i][header] : '-'}</Td>
          ))}
        </Tr>
      );
    }

    return rows;
  };

  return (
    <Box width={width} marginTop={marginTop}>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>{renderHeaders()}</Tr>
        </Thead>
        <Tbody>
          {renderRows()}
        </Tbody>
      </Table>
    </Box>
  );
};

export default StyledTable;
