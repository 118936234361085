/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      ContactId
      ContactName
      DivisionId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ContactId
        ContactName
        DivisionId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDivision = /* GraphQL */ `
  query GetDivision($id: ID!) {
    getDivision(id: $id) {
      id
      DivisionId
      DivisionName
      CompanyId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDivisions = /* GraphQL */ `
  query ListDivisions(
    $filter: ModelDivisionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDivisions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        DivisionId
        DivisionName
        CompanyId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      CompanyId
      CompanyName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        CompanyId
        CompanyName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listActivities = /* GraphQL */ `
  query ListActivities(
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ActivityId
        ActivityName
        StartTime
        Duration
        MainProduct
        Note
        ContactId
        date
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;


export const listDivisionsByCompany = /* GraphQL */ `
  query ListDivisionsByCompany($companyId: String!) {
    listDivisions(filter: {CompanyId: {eq: $companyId}}) {
      items {
        id
        DivisionId
        DivisionName
        CompanyId
      }
    }
  }
`;

export const listContactsByDivision = /* GraphQL */ `
  query ListContactsByDivision($divisionId: String!) {
    listContacts(filter: {DivisionId: {eq: $divisionId}}) {
      items {
        id
        ContactId
        ContactName
        DivisionId
      }
    }
  }
`;

export const listDivisionsByContact = /* GraphQL */ `
  query ListDivisionsByContact($contactId: String!) {
    listDivisions(filter: {ContactId: {eq: $contactId}}) {
      items {
        id
        DivisionId
        DivisionName
        CompanyId
      }
    }
  }
`;

export const listCompaniesByDivision = /* GraphQL */ `
  query ListCompaniesByDivision($divisionId: String!) {
    listCompanies(filter: {DivisionId: {eq: $divisionId}}) {
      items {
        id
        CompanyId
        CompanyName
      }
    }
  }
`;



