import React from 'react';
import { ChakraProvider, Box,Button,Flex } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import {Amplify} from 'aws-amplify';
import Home from './Home';
import SelectContact from './SelectContact';
import Activity from './Activity';
import Report from './Report';
import InputActivity from './InputActivity';
import ActivityList from './ActivityList';
import awsmobile from "./aws-exports";
import MonthlyReport from './MonthlyReport';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
Amplify.configure(awsmobile)

function App() {

  const printDocument = () => {
    const input = document.getElementById('divToPrint');
    html2canvas(input, { scale: 0.9 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'landscape', // 横向きに設定
        format: 'a4' // A4サイズを使用
      });
      pdf.addImage(imgData, 'PNG', 0,0);
      pdf.save("download.pdf");
    });
  };
  return (
    <ChakraProvider>
      <Router>
        <Box flex="1">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/activity" element={<Activity />} />
            <Route path="/input-activity" element={<InputActivity />} />
            <Route path="/select-contact" element={<SelectContact />} />
            <Route path="/activity-list" element={<ActivityList />} />
            <Route path="/report" element={<Report />} />
            <Route path="/monthly-report" element={<MonthlyReport />} />
          </Routes>
        </Box>
        <Box
          as="footer"
          bg="blue.600"
          p={4}
          color="white"
          position="fixed"
          bottom="0"
          left="0"
          right="0"
          display="flex" // フレックスボックスとして表示
          justifyContent="space-between" // フレックスアイテムを右寄せに
          textAlign="center"
          alignItems="center"
        >
            <Button onClick={printDocument} colorScheme="white">PDFをダウンロード</Button>
            <Box>
            <Link to="/" style={{ marginRight: '15px' }}>ホーム</Link>
            <Link to="/activity" style={{ marginRight: '15px' }}>活動</Link>
            <Link to="/select-contact" style={{ marginRight: '15px' }}>入力</Link>
            <Link to="/monthly-report" style={{ marginRight: '15px' }}>月次報告</Link>
            <Link to="/report" style={{ marginRight: '15px' }}>帳票</Link>
          </Box>
        </Box>
      </Router>
    </ChakraProvider>
  );
}

export default App;

