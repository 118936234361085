import React from 'react';
import { Box } from '@chakra-ui/react';

function Report() {
  return (
    <Box p={4}>
      準備中
    </Box>
  );
}

export default Report;
