const mockDataSets =[ 
  {
  "Customer": "アイリスオーヤマ",
  "Application": "業務用清掃ロボット",
  "Products": "STM",
  "Project Name": "業務用清掃ロボット",
  "End User": "アイリスオーヤマ",
  "MP/Date": "企画検討",
  "Application2":"車輪駆動用",
  "Territory": "コンシューマーテクノロジーセールス東日本SU1",
  "Unit/set": "2pcs/set",
  "Target Price": "60USD(仮)",
  "TTL QTY/Y": "6K",
  "MNB QTY/Y": "6K",
  "TTL AMT/Y": "360,000USD",
  "MNB AMT/Y": "360,000USD",
  "ScheduleTableData": [
    [
      { date: "2023年12月", data: "引き合い" },
      { date: "2023年1月", data: "製品初回提案" },
      { date: "-", data: "-" },
      { date: "2025年9月", data: "量産" },
    ],
  ],
  "BasicTableData": [
    {
      "YOKOTEN": "-",
      "Organizaton Name": "コンシューマーテクノロジーセールス東日本SU1",
      "Sales Person Name": "高橋秀直",
    }],
  "YokotenTableData": [
    {
      "Other Product": "-",
      "Yokoten": "-"
    },
    {
      "Other Product": "-",
      "Yokoten": "-"
    },
  ],
  "Photo": "1.png",
  "Type": "開発",
  "Current Status":"・業務用清掃ロボット車輪駆動用に新規引合入手。25年モデルより、アイリスオーヤマ自社開発\n・自社生産となり現行MOON’S社(競合)からのリプレース案件となる。\n・構成として、モータ＋ギヤBOX＋エンコーダとなり、まずは競合品の代替品での提案を行う。BLIMでの対応可能ｱｲﾃﾑなく ｽﾃｯﾋﾟﾝｸﾞﾓｰﾀでの対応可否統括部へ打診中"
},
{
  "Customer": "アイリスオーヤマ",
  "Application": "送風機",
  "Products": "DNQ04F",
  "Project Name": "送風機",
  "End User": "アイリスオーヤマ",
  "MP/Date": "企画検討",
  "Application2":"送風用BLDC",
  "Territory": "コンシューマーテクノロジーセールス東日本SU1",
  "Unit/set": "4pcs/set",
  "Target Price": "4.00USD(仮)",
  "TTL QTY/Y": "50K",
  "MNB QTY/Y": "50K",
  "TTL AMT/Y": "200,000USD",
  "MNB AMT/Y": "200,000USD",
  "ScheduleTableData": [
    [
      { date: "2023年11月", data: "引き合い" },
      { date: "2023年12月", data: "モータ初回提案" },
      { date: "-", data: "-" },
      { date: "2025年4月", data: "量産" },
    ],
  ],
  "BasicTableData": [
    {
      "YOKOTEN": "-",
      "Organizaton Name": "コンシューマーテクノロジーセールス東日本SU1",
      "Sales Person Name": "高橋秀直",
    }],
  "YokotenTableData": [
    {
      "Other Product": "-",
      "Yokoten": "-"
    },
    {
      "Other Product": "-",
      "Yokoten": "-"
    },
  ],
  "Photo": "2.png",
  "Type": "開発",
  "Current Status":"・スーパーマーケットなどの天井に発生するカビを抑制するため用の送風機用BLDC引合入手。\n・アイリスオーヤマとして、送風機-カビの発生状況を確認するためのシステム（温湿度）を開発中。\n・DNQ04MF（単速度品）を提案し、次年度モデルに検討していく事となった。\n・現在、客先内部でスケジュールを立てており、その後サンプル納入を行っていく。"
},
{
  "Customer": "（株)エフ・シー・シー",
  "Application": "検査用AGV",
  "Products": "EMTモーター（BLDC65)",
  "Project Name": "送風機",
  "End User": "（株)エフ・シー・シー",
  "MP/Date": "2023年",
  "Application2":"車輪駆動用",
  "Territory": "コンシューマーテクノロジーセールス東日本SU1",
  "Unit/set": "1pcs/set",
  "Target Price": "16.00USD(仮)",
  "TTL QTY/Y": "30K",
  "MNB QTY/Y": "30K",
  "TTL AMT/Y": "60,000USD",
  "MNB AMT/Y": "60,000USD",
  "ScheduleTableData": [
    [
      { date: "2023年9月", data: "客先検討" },
      { date: "-", data: "-" },
      { date: "-", data: "-" },
      { date: "未定", data: "量産" },
    ],
  ],
  "BasicTableData": [
    {
      "YOKOTEN": "-",
      "Organizaton Name": "コンシューマーテクノロジーセールス東日本SU1",
      "Sales Person Name": "名取猛",
    }],
  "YokotenTableData": [
    {
      "Other Product": "-",
      "Yokoten": "-"
    },
    {
      "Other Product": "-",
      "Yokoten": "-"
    },
  ],
  "Photo": "3.png",
  "Type": "開発",
  "Current Status":"用途は工場、トンネル等での使用している照明についている照度センサーが正常に機能しているかを検査するAGV。\n現在人が行っているが、ＡＧＶで対応できないか検討中。\n客先要望に対応したＢＬＤＣ＋エンコーダーを選定し提案予定。\n当社で対応可能な仕様は回答済みです。"
},
]
export default mockDataSets;
