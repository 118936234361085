import React, { useState, useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { generateClient } from "aws-amplify/api";
import { listActivities,listContacts,listDivisions, listCompanies } from './graphql/queries';
import { } from './graphql/queries';

const client = generateClient();

function ActivityList() {
  const [activities, setActivities] = useState([]);

  const fetchActivities = async () => {
    try {
      // Activityリストを取得
      const activityData = await client.graphql({ query: listActivities });
      const activitiesWithDetails = []; // 関連情報を追加した activities
  
      for (const activity of activityData.data.listActivities.items) {
        let company, division, contact;
        const contactData = await client.graphql({
          query: listContacts,
          variables: { filter: { ContactId: { eq: activity.ContactId } } }
        });
        contact = contactData.data.listContacts.items[0]; // 仮定: 各ContactIdに対して1つのContactが存在する
  
        if (contact) {
          // ContactのDivisionIdからDivisionを取得
          const divisionData = await client.graphql({
            query: listDivisions,
            variables: { filter: { DivisionId: { eq: contact.DivisionId } } }
          });
          division = divisionData.data.listDivisions.items[0]; // 仮定: 各DivisionIdに対して1つのDivisionが存在する
  
          if (division) {
            // DivisionのCompanyIdからCompanyを取得
            const companyData = await client.graphql({
              query: listCompanies,
              variables: { filter: { CompanyId: { eq: division.CompanyId } } }
            });
            company = companyData.data.listCompanies.items[0]; // 仮定: 各CompanyIdに対して1つのCompanyが存在する
          }

          if (contact && division && company) {
            // activityに関連情報を追加
            const activityWithDetails = {
              ...activity,
              companyName: company.CompanyName,
              divisionName: division.DivisionName,
              contactName: contact.ContactName,
            };
            activitiesWithDetails.push(activityWithDetails);
          }
        }
        setActivities(activitiesWithDetails); 
    }
   } catch (e) {
      console.error('Error fetching activities:', e);
    }
  };

  useEffect(() => {
    fetchActivities();
  }, []);

  return (
    <Box p={4}>
      <Text fontSize="xl">活動履歴</Text>
      {activities.map((activity, idx) => (
        <Box key={idx} p={4} shadow="md" borderWidth="1px">
          <Text mt={2}>会社名: {activity.companyName}</Text>
          <Text mt={2}>部署名: {activity.divisionName}</Text>
          <Text mt={2}>担当者名: {activity.contactName}</Text>
          <Text mt={2}>活動名: {activity.ActivityName}</Text>
          <Text mt={2}>活動日: {activity.date}</Text>
          <Text mt={2}>メモ: {activity.Note}</Text>
        </Box>
      ))}
    </Box>
  );
}

export default ActivityList;
