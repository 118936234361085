import { Box, Text,VStack } from '@chakra-ui/react';

// カスタムBoxコンポーネント
const StyledBox = ({ width, height, label, children, ...props }) => {

    const textLines = children.split('\n').map((line, index) => (
        // keyはユニークである必要があるため、indexを使用
        <Text fontSize="sm" key={index}>{line}</Text>
      ));
  return (
    <Box
      position="relative" // 相対位置
      width={width} // 幅
      height={height} // 高さ
      border="1px"
      borderColor="gray.500"
      borderStyle="solid"
      p={4}
      {...props} // その他のpropsを展開
    >
      {/* ボーダー上のテキスト */}
      <Text
        position="absolute" // 絶対位置
        top="-10px" // 枠の上に配置、適宜調整が必要かもしれません
        left="16px" // 左側からの位置、適宜調整が必要かもしれません
        bg="white" // 背景色を白に設定
        px={2} // 左右のパディング
        fontSize="sm" // フォントサイズ、適宜調整が必要かもしれません
      >
        {label}
      </Text>
      <VStack spacing={1} align="start">
        {textLines}
      </VStack>
    </Box>
  );
}

export default StyledBox;




