import React, { useState, useEffect } from 'react';
import { Box, InputGroup, Input, InputRightElement, IconButton, Text, Flex,
  Menu,MenuButton,MenuList,MenuItem, Button,useDisclosure} from '@chakra-ui/react';
import { ChevronDownIcon,CloseIcon } from '@chakra-ui/icons';
import { listCompanies,listDivisionsByCompany, listContactsByDivision } from './graphql/queries';
import { createContact } from './graphql/mutations';
import { generateClient } from "aws-amplify/api";
import { useNavigate } from 'react-router-dom';

const client = generateClient()

function SelectContact() {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [companySearchTerm, setCompanySearchTerm] = useState('');
  const [divisionSearchTerm, setDivisionSearchTerm] = useState('');
  const [contactSearchTerm, setContactSearchTerm] = useState('');
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [filteredDivisions, setFilteredDivisions] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [isInputVisible, setInputVisible] = useState(false);
  const { isOpen, onToggle } = useDisclosure();
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  async function fetchCompanies() {
    let allCompanies = [];
    let nextToken = null;

    do {
      const { data } = await client.graphql({
        query: listCompanies,
        variables: { nextToken }
      });

      allCompanies = [...allCompanies, ...data.listCompanies.items];
      nextToken = data.listCompanies.nextToken;
    } while (nextToken);

    setCompanies(allCompanies);
  }

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    if (companySearchTerm) {
      const filtered = companies.filter(company =>
        company.CompanyName.toLowerCase().includes(companySearchTerm.toLowerCase())
      );
      setFilteredCompanies(filtered);
    } else {
      setFilteredCompanies(companies);
    }
  }, [companySearchTerm, companies]);

  function clearCompanyInput() {
    setCompanySearchTerm('');
    setFilteredCompanies([]);
  }

  function handleCompanySelect(company) {
    setSelectedCompany(company);
    setCompanySearchTerm(company.CompanyName); 
  }

  useEffect(() => {
    async function fetchDivisions() {
      if (selectedCompany) {
        const divisionData = await client.graphql({
          query: listDivisionsByCompany,
          variables: { companyId: selectedCompany.CompanyId }
        });
        setDivisions(divisionData.data.listDivisions.items);
      }
    }
    fetchDivisions();
  }, [selectedCompany]);

  useEffect(() => {
    async function fetchContacts() {
      if (selectedDivision) {
        const contactData = await client.graphql({
          query: listContactsByDivision,
          variables: { divisionId: selectedDivision.DivisionId }
        });
        setContacts(contactData.data.listContacts.items);
      }
    }
    fetchContacts();
  }, [selectedDivision]);

  useEffect(() => {
    if (divisionSearchTerm) {
      const filtered = divisions.filter(division =>
        division.DivisionName.toLowerCase().includes(divisionSearchTerm.toLowerCase())
      );
      setFilteredDivisions(filtered);
    } else {
      setFilteredDivisions(divisions);
    }
  }, [divisionSearchTerm, divisions]);

  function handleDivisionSelect(division) {
      setSelectedDivision(division);
      setDivisionSearchTerm(division.DivisionName); 
      setFilteredDivisions([]);
  } 

  function clearDivisionInput() {
    setDivisionSearchTerm('');
  }

  useEffect(() => {
    if (contactSearchTerm) {
      const filtered = contacts.filter(contact =>
        contact.ContactName.toLowerCase().includes(contactSearchTerm.toLowerCase())
      );
      setFilteredContacts(filtered);
    } else {
      setFilteredContacts(contacts);
    }
  }, [contactSearchTerm, contacts]);

  function handleContactSelect(contact) {
      setSelectedContact(contact);
      setContactSearchTerm('');
      navigate('/input-activity', {
        state: { 
          selectedContact: contact,
          selectedDivision: selectedDivision,
          selectedCompany: selectedCompany
        } });
  } 

  function clearContactInput() {
    setContactSearchTerm('');
  }

  return (
  <Flex direction="column">
    <Box position="relative">
      <InputGroup width="300px">
        <Input
          placeholder="会社名（一部記載あり）"
          value={companySearchTerm}
          onChange={(e) => {
            setCompanySearchTerm(e.target.value);
          }}
          marginBottom="2"
        />
        {companySearchTerm && (
          <IconButton
            aria-label="Clear input"
            icon={<CloseIcon />}
            size="sm"
            onClick={clearCompanyInput}
          />
        )}
        <IconButton
          aria-label="Open menu"
          icon={<ChevronDownIcon />} // プルダウンアイコン
          size="sm"
          onClick={() => setShowMenu(!showMenu)} // showMenuはプルダウンメニューの表示状態を管理するステート
        />
      </InputGroup>
      <Box position="relative" width="300px">
      {showMenu && filteredCompanies.length > 0 && (
        <Box position="absolute" width="full" bg="white" zIndex="10" mt="1" shadow="md">
          {filteredCompanies.map((company, index) => (
            <Text
              key={index}
              p="2"
              borderBottomWidth="1px"
              onClick={() => handleCompanySelect(company)}
            >
              {company.CompanyName}
            </Text>
          ))}
        </Box>
      )}
      </Box>
    </Box>

    <Flex direction="column">
      <InputGroup width="300px" marginTop="50px">
        <Input
          placeholder="部門名（一部記載あり）"
          value={divisionSearchTerm}
          onChange={(e) => setDivisionSearchTerm(e.target.value)}
          marginBottom="2"
        />
        {divisionSearchTerm && (
          <InputRightElement>
            <IconButton
              aria-label="Clear input"
              icon={<CloseIcon />}
              size="sm"
              onClick={clearDivisionInput}
            />
          </InputRightElement>
        )}
      </InputGroup>
      <Box position="relative" width="300px">
        {filteredDivisions.length > 0 && (
          <Box position="absolute" width="full" bg="white" zIndex="10">
            {filteredDivisions.map((division, index) => (
              <Text key={index} p="2" borderBottomWidth="1px" onClick={() => handleDivisionSelect(division)}>
                {division.DivisionName}
              </Text>
            ))}
          </Box>
        )}
      </Box>
    </Flex>

    <Flex direction="column">
      <InputGroup width="300px" marginTop="50px">
        <Input
          placeholder="コンタクト名（一部記載あり）"
          value={contactSearchTerm}
          onChange={(e) => setContactSearchTerm(e.target.value)}
          marginBottom="2"
        />
        {contactSearchTerm && (
          <InputRightElement>
            <IconButton
              aria-label="Clear input"
              icon={<CloseIcon />}
              size="sm"
              onClick={clearContactInput}
            />
          </InputRightElement>
        )}
      </InputGroup>
      <Box position="relative" width="300px">
        {filteredContacts.length > 0 && (
          <Box position="absolute" width="full" bg="white" zIndex="10">
            {filteredContacts.map((contact, index) => (
              <Text key={index} p="2" borderBottomWidth="1px" onClick={() => handleContactSelect(contact)}>
                {contact.ContactName}
              </Text>
            ))}
          </Box>
        )}
      </Box>
    </Flex>
  </Flex>
  );
}

export default SelectContact;

