import React from 'react';
import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

function Home() {
    const navigate = useNavigate();
    const handleSelectContactClick = () => {
        navigate('/select-contact');
        };
    const handleSelectMonthlyReport = () => {
          navigate('/monthly-report');
          };

  return (
    <Box p={4}>
      <Box p={4} position="relative">
        <HStack spacing={4} position="absolute" left={0} top={0}>
            <Button
            width="200px"
            height="100px"
            marginLeft="50px"
            bg="blue.400"
            textColor="white"
            onClick={handleSelectContactClick}
            >
            活動入力
            </Button>
            <Button
            width="200px"
            height="100px"
            marginLeft="50px"
            bg="blue.400"
            textColor="white"
            onClick={handleSelectMonthlyReport}
            >
            月次報告
            </Button>
        </HStack>
      </Box>
    </Box>
  );
}

export default Home;
