import { Table, Thead, Tbody, Tr, Th, Td, Box } from '@chakra-ui/react';

const ScheduledTable = ({ scheduleTableData,marginLeft,width }) => {
  // ヘッダー（日付）を取得
  const headers = scheduleTableData[0].map(item => item.date);

  return (
    <Box overflowX="auto" border="1px solid gray" marginLeft={marginLeft} width={width}> 
      <Table variant="simple" size="sm" border="1px solid gray"> 
        <Thead>
          <Tr>
            {headers.map(header => (
              <Th key={header} border="1px solid gray" textAlign="center">{header}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {scheduleTableData.map((row, rowIndex) => (
            <Tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <Td key={`${rowIndex}-${cellIndex}`} border="1px solid gray" textAlign="center">{cell.data}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default ScheduledTable;
