import React, {useState} from 'react';
import { useLocation } from 'react-router-dom';
import { Box,Text,Button,InputGroup, Input, InputLeftAddon,Textarea, Flex,useToast,InputRightElement  } from '@chakra-ui/react';
import { generateClient } from "aws-amplify/api";
import { createActivity as createActivityMutation } from './graphql/mutations';

const client = generateClient();

function InputActivity() {
  const location = useLocation();
  const toast = useToast();
  const { selectedContact, selectedDivision, selectedCompany } = location.state || {};
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [time, setTime] = useState(new Date().toLocaleTimeString('it-IT').slice(0, 5));
  const [duration, setDuration] = useState(60);
  const [activityName, setActivityName] = useState('');
  const [mainProduct, setMainProduct] = useState('');
  const [note, setNote] = useState('');

  const handleDateChange = (event) => setDate(event.target.value);
  const handleTimeChange = (event) => setTime(event.target.value);
  const handleDurationChange = (event) => setDuration(event.target.value);
  const handleActivityNameChange = (event) => setActivityName(event.target.value);
  const handleMainProductChange = (event) => setMainProduct(event.target.value);
  const handleNoteChange = (event) => setNote(event.target.value);

  async function saveActivity() {
    const startTime = `${time}:00.000Z`;
    const activityData = {
      ActivityId: selectedCompany.CompanyId + '-' + selectedDivision.DivisionId + '-' + selectedContact.ContactId,
      ActivityName: activityName,
      StartTime: startTime,
      date:date,
      Duration: duration.toString(),
      MainProduct: mainProduct,
      Note: note,
      ContactId: selectedContact.ContactId,
    };

    try {
      await client.graphql({
        query: createActivityMutation,
        variables: { input: activityData }
      });
      toast({
        title: '保存成功',
        description: 'データが正常に保存されました。',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (e) {
      console.error('API call error:', e);
      toast({
        title: '保存失敗',
        description: '保存に失敗しました。管理者に連絡ください。',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }

  return (
    <Flex marginTop="30px" marginLeft="0px"direction="column">
      <InputGroup marginBottom="5px">
        <InputLeftAddon children="会社" />
        <Input isReadOnly placeholder="会社名" value={selectedCompany.CompanyName}/>
        <InputRightElement width="auto">
        <Text fontSize="sm" color="gray.500">
          （変更不可）
        </Text>
        </InputRightElement>
      </InputGroup>

      <InputGroup marginBottom="5px">
        <InputLeftAddon children="部署"/>
        <Input isReadOnly placeholder="部署名" value={selectedDivision.DivisionName} />
        <InputRightElement width="auto">
        <Text fontSize="sm" color="gray.500">
          （変更不可）
        </Text>
        </InputRightElement>
      </InputGroup>

      <InputGroup marginBottom="5px">
        <InputLeftAddon children="担当者" border="none" _focus={{ borderColor: 'transparent' }} />
        <Input isReadOnly placeholder="担当者名" value={`${selectedContact.ContactName} 様`}/>
        <InputRightElement width="auto">
        <Text fontSize="sm" color="gray.500">
          （変更不可）
        </Text>
        </InputRightElement>
      </InputGroup>

      <InputGroup marginTop="20px">
        <InputLeftAddon children="日付" />
        <Input type="date" placeholder="日付を選択" value={date} onChange={handleDateChange} />
      </InputGroup>
      
      <InputGroup marginTop="10px">
        <InputLeftAddon children="開始時刻" />
        <Input type="time" placeholder="開始時刻を選択" value={time} onChange={handleTimeChange} />
      </InputGroup>
      
      <InputGroup marginTop="10px">
        <InputLeftAddon children="面談時間" />
        <Input type="number" placeholder="面談時間 (5分単位で記載)" step="5" value={duration} min="5" onChange={handleDurationChange} />
      </InputGroup>

      <InputGroup marginTop="10px">
        <InputLeftAddon children="活動名" />
        <Input placeholder="活動名を記載" value={activityName} onChange={handleActivityNameChange} />
      </InputGroup>

      <InputGroup marginTop="10px">
        <InputLeftAddon children="PR製品" />
        <Input placeholder="打ち合わせの主の製品名を記載" value={mainProduct} onChange={handleMainProductChange} />
      </InputGroup>
      
      <Textarea marginTop="10px" placeholder="自分のメモ、あるいは伝えたい内容" value={note} onChange={handleNoteChange} />
      
      <InputGroup marginTop="10px">
        <InputLeftAddon children="伝えたい連絡先" />
        <Input placeholder="伝えたい相手を選ぶ" />
      </InputGroup>

      <Flex justifyContent="center" marginTop="20px" marginBottom="20px">
        <Button width="100px" colorScheme="blue" onClick={saveActivity}>保存</Button>
      </Flex>
   </Flex>
  );
}

export default InputActivity;

