import { Table, Thead, Tbody, Tr, Th, Td, Box } from '@chakra-ui/react';

// カスタムテーブルコンポーネント
const StyledTable = ({ rows, columns, tableWidth, tableHeight, headerNames=[],data=[], ...props }) => {
  // セルのサイズを計算
  const cellWidth = `${100 / columns}%`;
  const cellHeight = `${tableHeight / rows}px`;

  // 列ヘッダーを生成する関数
  const renderHeaders = () => {
    return headerNames.map((name, index) => (
      <Th key={`header-${index}`} textAlign="center" border="1px solid black">
        {name}
      </Th>
    ));
  };

  // 行とセルを生成する関数
  const renderRows = () => {
    return data.map((row, rowIndex) => (
      <Tr key={rowIndex}>
        {headerNames.map((header, columnIndex) => (
          <Td key={`${rowIndex}-${columnIndex}`} border="1px solid black" textAlign="center">
            {row[header]}
          </Td>
        ))}
      </Tr>
    ));
  };

  return (
    // Boxからborderプロパティを削除しました。
    <Box width={tableWidth} {...props}>
      <Table size="sm" border="1px solid black">
        <Thead>
          <Tr>{renderHeaders()}</Tr>
        </Thead>
        <Tbody>{renderRows()}</Tbody>
      </Table>
    </Box>
  );
};

export default StyledTable;



